// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'development',
  production: false,
  version: '0.1.0 beta',
  debug: true,
  refreshTokenMargin: 300, // seconds // 3540 is 59 minite margin, 300
  // version: '0.1.0 beta',
  apiEndpointRoot: 'https://dev-api.quatro90.com/api',
  // apiEndpointRoot: 'https://quatro90-admin-dev.azurewebsites.net/api',
  apiEndpointVersion: '/v1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
