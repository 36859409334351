import { Routes } from '@angular/router';
import { ErrorComponent } from './shared/components/error/error.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { authenticatedGuard } from './authentication/guards/authenticated.guard';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { NoSidebarLayoutComponent } from './layouts/no-sidebar-layout/no-sidebar-layout.component';
import { SignOutComponent } from './account/components/sign-out/sign-out.component';

export const MainRoutes: Routes = [
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./authentication/authentication-routing').then(
        (mod) => mod.AuthenticationRoutes,
      ),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account-routing').then((mod) => mod.AccountRoutes),
  },
  {
    path: 'help',
    loadChildren: () =>
      import('./pages/help/help-routing').then((mod) => mod.HelpRoutes),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard-routing').then(
        (mod) => mod.DashboardRoutes,
      ),
  },
  {
    path: 'team',
    loadChildren: () =>
      import('./pages/team/team-routing').then((mod) => mod.TeamRoutes),
  },
  {
    path: 'tenant',
    loadChildren: () =>
      import('./pages/tenant/tenant-routing').then((mod) => mod.TenantRoutes),
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: '', component: HomeComponent, canActivate: [authenticatedGuard] },
      {
        path: 'my-groups',
        loadChildren: () =>
          import('./pages/my-groups/my-groups-routing').then(
            (mod) => mod.MyGroupsRoutes,
          ),
      },
      {
        path: 'member',
        loadChildren: () =>
          import('./entity/user/user.routing').then(
            (mod) => mod.UserAccountRoutes,
          ),
      },
      {
        path: 'person',
        loadChildren: () =>
          import('./entity/person/person.routing').then(
            (mod) => mod.PersonRoutes,
          ),
      },
      {
        path: 'contentchannel',
        loadChildren: () =>
          import('./pages/content-channel/content-channel-routing').then(
            (mod) => mod.ContentChannelRoutes,
          ),
      },

      {
        path: 'project',
        loadChildren: () =>
          import('./entity/project/project.routing').then(
            (mod) => mod.ProjectRoutes,
          ),
      },
      // {
      //   path: 'admin',
      //   loadChildren: () =>
      //     import('./admin/admin-routing').then((mod) => mod.AdminRoutes),
      // },
      {
        path: 'xperience',
        loadChildren: () =>
          import('./xperience/xperience.routing').then(
            (mod) => mod.XperienceRoutes,
          ),
      },
    ],
  },
  {
    path: '',
    component: NoSidebarLayoutComponent,
    children: [
      { path: 'sign-out', pathMatch: 'full', component: SignOutComponent },
      { path: 'page-not-found', component: PageNotFoundComponent },
      { path: 'error', component: ErrorComponent },
      { path: '**', redirectTo: 'page-not-found' },
    ],
  },
];
