<div class="modal" [ngClass]="{ closing: closing }" [@enterAnimation]>
  <div
    class="background"
    (click)="closeModalOnClick($event)"
    (keyup)="closeModalOnEscape($event)"
    aria-label="Close modal"
    tabindex="0"
    role="button"
  ></div>
  <div
    *ngIf="navigationHeaderData$ | async as navigationHeaderData"
    [ngClass]="navigationHeaderData.theme.class"
    [ngClass]="template ? template : ''"
    class="content"
  >
    <button
      type="button"
      aria-label="Close modal"
      *ngIf="withCloseButton"
      class="close"
      (click)="closeModalOnClick($event)"
    >
      <span class="mci-close"></span>Close
    </button>
    <ng-content></ng-content>
  </div>
</div>
